// App.js

import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import HomePage from './components/HomePage';
import css from './App.css';
import { motion } from 'framer-motion';
import Footer from './components/Footer';
import CatalogPage from './components/CatalogPage';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import ProductPage from './components/ProductPage';
import NotFound from './components/NotFound';
import SearchResults from './components/SearchResults';
import ComingPage from './components/ComingPage';
import FaqPage from './components/FaqPage';
import backimg from './assets/images/backimg.svg'
function App() {
  


  return (
    
    <div className="App">
      
      <Router>
      <Header/>
          <Routes>
              <Route path="/" element={<ComingPage />}/>
              <Route path="/home" element={<HomePage />}/>
              <Route path="/catalog/*" element={<CatalogPage />} />
              <Route path="/catalog/:id" element={<CatalogPage />} />
              <Route path="/catalog/product" element={<ProductPage/>}/>
              <Route path="/catalog/product/:productId" element={<ProductPage/>}/>
              <Route path="/search" element={<SearchResults />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer/>
      </Router>


        
       

        
        <motion.img src={backimg} style={{position: 'absolute', zIndex: -1, width: '200px', top: '100%', left: '12%', transform: 'rotate(90deg)'}}
        animate={{
          y: 15
        }}
        transition={{
          repeat: Infinity,
          repeatDelay: 1,
          delay: 1,
          repeatType: "mirror",
          duration: 1.5
        }}
        />

        <motion.img src={backimg} style={{position: 'absolute', zIndex: -1, width: '200px', top: '130%', right: '8%', transform: 'rotate(40deg)'}}
        animate={{
          y: 25
        }}
        transition={{
          repeat: Infinity,
          repeatDelay: 2,
          delay: 2,
          repeatType: "mirror",
          duration: 3
        }}
        />
        
        <motion.img src={backimg} style={{position: 'absolute', zIndex: -1, width: '200px', top: '160%', left: '13%', transform: 'rotate(90deg)'}}
        animate={{
          y: 30
        }}
        transition={{
          repeat: Infinity,
          repeatDelay: 2,
          delay: 2,
          repeatType: "mirror",
          duration: 6
        }}
        />

        <motion.img src={backimg} style={{position: 'absolute', zIndex: -1, width: '200px', top: '190%', right: '7%', transform: 'rotate(40deg)'}}/>
        
        <motion.img src={backimg} style={{position: 'absolute', zIndex: -1, width: '200px', top: '220%', left: '15%', transform: 'rotate(90deg)'}}/>
    </div>
  );
}

export default App;
